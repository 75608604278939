<template>
  <v-card class="my-0 pa-4">
    <v-data-table
      :headers="headers"
      :items="listProducts"
      :loading="isLoadingProducts"
      :server-items-length="productsMeta.totalItems"
      :options.sync="options"
      :items-per-page="30"
      :footer-props="{
        'items-per-page-options': [5, 30, 50],
        'disable-pagination': isLoadingProducts,
      }"
    >
      <template v-slot:top>
        <v-toolbar
          flat
          class="toolbar-packs-and-bundles"
        >
          <v-toolbar-title>{{
            $t("setup.grouped_products.list")
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            v-if="$admin.can('product-export')"
            color="primary"
            outlined
            class="ma-0 mr-2"
            @click="getCSV(options)"
            :loading="is_loading_csv"
            :disabled="is_loading_csv || true"
          >
            <strong> {{ $t("import_csv") }} </strong>
          </v-btn>
          <v-btn
            v-if="$admin.can('product-export')"
            color="primary"
            class="ma-0 mr-2"
            outlined
            @click="getCSV(options)"
            :loading="is_loading_csv"
            :disabled="is_loading_csv"
          >
            <strong>{{ $t("export_csv") }}</strong>
          </v-btn>
          <v-btn
            v-if="$admin.can('packs-and-bundles-create')"
            color="primary"
            class="ma-0"
            @click="createPackAndBundle()"
          >
            {{ $t("create_new") }}
          </v-btn>

          <delete-products></delete-products>
        </v-toolbar>
        <v-row class="mt-2">
          <v-col class="pe-0">
            <v-text-field
              outlined
              dense
              hide-details
              v-model="options.search"
              :label="$t('search')"
              :disabled="is_loading_product"
            ></v-text-field>
          </v-col>
          <v-col class="pe-0">
            <v-autocomplete
              clearable
              outlined
              dense
              hide-details
              v-model="options.category_parent_id"
              :items="categories"
              item-text="name"
              item-value="id"
              :loading="isLoadingCategories"
              :label="$t('category')"
              :disabled="is_loading_product"
              @focus="getCategories"
              @change="loadCategory()"
            ></v-autocomplete>
          </v-col>
          <v-col class="pe-0">
            <v-autocomplete
              clearable
              outlined
              dense
              hide-details
              v-model="options.category_id"
              :items="sub_categories"
              item-text="name"
              item-value="id"
              :loading="isLoadingSubCategories"
              :label="$t('sub_category')"
              :disabled="is_loading_product"
            ></v-autocomplete>
          </v-col>
          <v-col class="pe-0">
            <v-autocomplete
              clearable
              outlined
              dense
              hide-details
              v-model="options.status"
              :items="[
                {
                  value: 'active',
                  text: $t('Active'),
                },
                {
                  value: 'inactive',
                  text: $t('Inactive'),
                },
                {
                  value: 'new',
                  text: $t('New'),
                },
              ]"
              :label="$t('status')"
              :disabled="is_loading_product"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.image="{ item }">
        <v-img
          v-if="item.image"
          :src="item.image.url"
          max-height="50"
          max-width="50"
        />
        <v-img
          v-else
          src="@/assets/product.png"
          max-height="50"
          max-width="50"
        />
      </template>
     
      <template v-slot:item.express_status="{ item }">
        <v-chip :color="getColor(item.express_status)" dark>
          {{ item.express_status ? $t("active") : $t("inactive") }}
        </v-chip>
      </template>
      <template v-slot:item.type="{ item }">
        {{ $t("setup.product.type." + item.type) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="$admin.can('packs-and-bundles-view')"
          small
          class="mr-2"
          @click="showItem(item)"
        >
          mdi-eye
        </v-icon>
        <v-icon
          v-if="
            $admin.hasAny(['packs-and-bundles-view', 'product-update-content'])
          "
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="$admin.can('packs-and-bundles-delete')"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        {{ $t("no_data_available") }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";
import { helpers } from "@/mixins/helpers";
import categoryService from "@/store/services/category-service";
export default {
  mixins: [helpers],
  components: {
    DeleteProducts: () => import("../products/modals/DeleteProductsModal.vue"),
  },

  computed: {
    ...mapGetters({
      isLoadingCategories: "categories/isLoadingCategories",
      isLoadingSubCategories: "categories/isLoadingCategories",
      isLoadingShelves: "categories/isLoadingShelves",
      isLoadingBrands: "brands/isLoadingBrands",
      isLoadingHubs: "hubs/isLoadingHubs",
      isLoadingSections: "sections/isLoadingSections",
      isLoadingProviders: "providers/isLoadingProviders",
      isLoadingAttributes: "attributes/isLoadingAttributes",

      hubs: "hubs/listHubs",
      brands: "brands/listBrands",
      stores: "stores/listStores",
      categories: "categories/listRoots",
      shelves: "categories/listShelves",
      providers: "providers/listProviders",

      isLoadingProducts: "products/isLoadingProducts",
      listProducts: "products/listProducts",
      productsMeta: "products/meta",
    }),
  },

  watch: {
    options: {
      deep: true,
      handler: debounce(async function () {
        this.is_loading_product = true;
        await this.$store.dispatch("products/root", this.options).then(() => {
          this.is_loading_product = false;
        });
      }, 1000),
    },
    //
    // search_hub: debounce(function (search) {
    //   if (this.isLoadingHubs) return;
    //
    //   this.$store.dispatch("hubs/list", {
    //     itemsPerPage: 30,
    //     search,
    //   });
    // }, 1000),
  },

  data() {
    return {
      options: { itemsPerPage: 30, type: "compound" },
      search_hub: "",
      is_loading_product: false,
      is_loading_csv: false,
      sub_categories: [],

      headers: [
        {
          text: this.$t("image"),
          align: "start",
          value: "image",
          sortable: false,
        },
        {
          text: this.$t("pack_bundle_name"),
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: this.$t("type"),
          align: "start",
          sortable: true,
          value: "type",
        },
        // {
        //   text: this.$t("pack_bundle_id"),
        //   align: "start",
        //   sortable: true,
        //   value: "code",
        // },
        {
          text: this.$t("sku"),
          align: "start",
          sortable: true,
          value: "barcode",
        },
        {
          text: this.$t("express_status"),
          value: "express_status",
        },
        {
          text: this.$t("price"),
          value: "price",
          sortable: false,
        },
        {
          text: this.$t("created_at"),
          value: "created_at",
          sortable: true,
        },
        {
          text: this.$t("actions"),
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    createPackAndBundle() {
      this.$router.push({ name: "setup.grouped_products.create" });
    },
    showItem(item) {
      this.$router.push({
        name: "setup.grouped_products.view",
        params: { id: item.id },
      });
    },
    showItemLots(item) {
      this.$store.dispatch("products/openShowLot", item);
    },

    editItem(item) {
      this.$router.push({
        name: "setup.grouped_products.edit",
        params: { id: item.id },
      });
    },

    deleteItem(item) {
      this.$store.dispatch("products/openDeleteForm", item);
    },

  
    getColor(status) {
      if (status === "active" || status == 1) return "green";
      else return "red";
    },

    async getCSV(option) {
      this.is_loading_csv = true;
      option.with_hubs = false;
      await this.$store
        .dispatch("products/downloadCSV", option)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "products.csv");
          document.body.appendChild(link);
          // window.open(link);
          link.click();
          this.is_loading_csv = false;
        })
        .catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.statusText);
          this.is_loading_csv = false;
        });
    },
    loadCategory: debounce(async function (search) {
      if (this.options.category_parent_id) {
        const sub_categories = await categoryService.list({
          level: 3,
          parent_id: this.options.category_parent_id,
          search,
        });
        this.sub_categories = sub_categories;
      }
    }, 1000),
  },
};
</script>
<style>
.toolbar-packs-and-bundles .v-toolbar__content {
  padding: 0;
}
</style>